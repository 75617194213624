<template>
    <main class="my-account">
		
<section class="content-my-account">
	<div class="content-my-account-itens">
		<div class="content-my-account-itens-bg">
			<div class="container">
				<div class="content-my-account-itens-header">
					<router-link class="btn-return" to="/">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.586" height="10.582" viewBox="0 0 9.586 10.582"><path d="M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z" transform="translate(5.256 12.867)"/></svg>
						<p>voltar</p>
					</router-link>
					<h1 class="title">
						A sua conta
					</h1>
				</div>
			</div>
		</div>
		<div class="content-my-account-itens-body">
			<div class="container">
				<div class="content-account">
					<div class="content-account-element">
						<div class="row">
							<div class="col-12">
								<div class="content-account-element-header">
									<h2>O seu perfil</h2>
								</div>
							</div>
						</div>
						<form ref="formUpdate" @submit.prevent="onUpdate" class="content-account-element-body">
							<div class="row">
								<div class="col-lg-6">
									<div class="content-input">
										<label>Nome</label>
										<input type="text" name="name" :value="user.name" required/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="content-input">
										<label>Código Colaborador</label>
										<input type="text" :value="user.code" name="code" />
									</div>
								</div>
								<div class="col-lg-6">
									<div class="content-input">
										<label>Telefone</label>
										<input type="text" :value="user.phone" name="phone" required/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="content-input">
										<label>E-mail</label>
										<input type="email" :value="user.email" name="email" required/>
									</div>
								</div>
							</div>
							<button type="submit" class="btn-change">Alterar Perfil</button>
						</form>
					</div>
					<div class="content-account-element">
						<div class="row">
							<div class="col-12">
								<div class="content-account-element-header">
									<h2>Alterar Password</h2>
								</div>
							</div>
						</div>
						<form ref="formPassword" @submit.prevent="onPassword" class="content-account-element-body">
							<div class="row">
								<div class="col-lg-6">
									<div class="content-input">
										<label>Nova password</label>
										<input type="password" value="" name="password" placeholder="******" required/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="content-input">
										<label>Confirmar nova password</label>
										<input type="password" value="" name="password_confirmation" placeholder="******" required/>
									</div>
								</div>
							</div>
							<button type="submit" class="btn-change">Alterar Password</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

	</main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
	computed: {
		...mapGetters('User', ['user']),
	},
	methods:{		
        ...mapActions('App', ['loading']),  
		...mapActions('User', ['setUser']) ,
		async onPassword(){
			let formData = new FormData(this.$refs.formPassword);
			await this.save(formData)
			this.$refs.formPassword.reset()
		},  
        async onUpdate(el){            
            let formData = new FormData(this.$refs.formUpdate);
			await this.save(formData)
        },
		async save(formData){
			this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/update',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);				
				this.setUser(data.data);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });                
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
		}
    }
}
</script>
<style scoped>
.content-my-account-itens-bg{
	background-image: url('~@/assets/img/leads/banner_int.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
</style>
